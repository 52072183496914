import { downloadFileFromBuffer, enqueueToast, useAsyncCallback } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useState } from 'react'
import { trpc } from '../../client'
import { logger } from '../logger'

export function useDownloadInvoices({ invoiceId }: { invoiceId: string }): {
  download: (
    apiName: 'downloadInvoice' | 'downloadReceipt' | 'downloadMyInvoice' | 'downloadMyReceipt',
    fileName: string,
  ) => void
  downloadInProgress: boolean
} {
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const handleDownload = useAsyncCallback(
    async (
      apiName: 'downloadInvoice' | 'downloadReceipt' | 'downloadMyInvoice' | 'downloadMyReceipt',
      fileName: string,
    ) => {
      try {
        setDownloadInProgress(true)
        const result = await trpc.invoices[apiName].mutate({ invoiceId })
        if (isFailure(result)) {
          enqueueToast(result.problem.message, { variant: 'warning' })
          return
        }
        await downloadFileFromBuffer(result.output, fileName)
      } catch (_err) {
        logger.warning('Failed to download file')
        enqueueToast('Échec du téléchargement du fichier.', { variant: 'danger' })
      } finally {
        setDownloadInProgress(false)
      }
    },
    [invoiceId],
  )

  return { download: handleDownload, downloadInProgress }
}
